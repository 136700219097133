import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplateNoReload,
//   validateCaptcha,
// } from 'react-simple-captcha';
// @mui
import { Stack, Alert, IconButton, InputAdornment, Link, Box, InputBase, Container } from '@mui/material';
// routes
import { Link as RouterLink } from 'react-router-dom';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { GoldBorderLoadingButton } from '../../../components/nav-section/vertical/style';
import { closeLogin, openForgotPwd } from '../../../redux/slices/authPages';
import { useDispatch } from '../../../redux/store';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(translate('x_is_required', { x: translate('username') })),
    password: Yup.string().required(translate('x_is_required', { x: translate('password') })),
    // captcha: Yup.string().required(translate('x_is_required', { x: translate('captcha') })),
  });

  const defaultValues = {
    username: '',
    password: '',
    // captcha: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newData = JSON.parse(JSON.stringify(data));
      // if (!validateCaptcha(data?.captcha)) throw new Error(translate('invalid_captcha'));

      // delete newData.captcha;

      await login(newData);
      dispatch(closeLogin());
    } catch (error) {
      console.error(error);

      // reset();

      // if (isMountedRef.current) {
      setError('afterSubmit', { ...error, message: translate(error.message) });
      // }
    }
  };

  // useEffect(() => {
  //   loadCaptchaEnginge(4);
  // }, []);

  const doSubmit = () => {
    // <! --   let's assume there is an HTML input text box with id 'user_captcha_input' to get user input -->
    // const user_captcha_value = document.getElementById('user_captcha_input').value;
    // if (validateCaptcha(user_captcha_value) == true) {
    //   alert('Captcha Matched');
    // } else {
    //   alert('Captcha Does Not Match');
    // }
  };

  return (
    <Container maxWidth="xs">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: '100%' }}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFTextField
            name="username"
            label={translate('username')}
            placeholder={translate('username')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:user" />
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="password"
            label={translate('password')}
            placeholder={translate('password')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="material-symbols:lock" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" tabIndex={-1}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <RHFTextField
            name="captcha"
            label={translate('captcha')}
            placeholder={translate('captcha')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LoadCanvasTemplateNoReload />
                </InputAdornment>
              ),
            }}
          /> */}

          <Stack alignItems="center" justifyContent="space-between" spacing={1}>
            <Link
              component={RouterLink}
              variant="subtitle2"
              onClick={() => {
                dispatch(closeLogin());
                dispatch(openForgotPwd());
              }}
              to={'#'}
            >
              {`${translate('forgot_password')}?`}
            </Link>
            <GoldBorderLoadingButton fullWidth size="large" type="submit" variant="outlined" loading={isSubmitting}>
              {translate('confirm')}
            </GoldBorderLoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Container>
  );
}
