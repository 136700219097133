import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, Divider, Grid, Card, CircularProgress } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAVBAR, S3_ASSETS_URL } from '../../../config';
// components
import Scrollbar from '../../../components/Scrollbar';
import Image from '../../../components/Image';
import { GoldBorderButton } from '../../../components/nav-section/vertical/style';
import useAuth from '../../../hooks/useAuth';
import NotificationsPopover from '../header/NotificationsPopover';
import AccountPopover from '../header/AccountPopover';
import { useDispatch, useSelector } from '../../../redux/store';
import { openLogin, openRegister } from '../../../redux/slices/authPages';
import Iconify from '../../../components/Iconify';

import { GAME_CATEGS, appLocale } from '../../../utils/const';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import { setSelectedGameTab } from '../../../redux/slices/gameTab';
import SocialIcons from '../../../sections/about/SocialIcons';
import useLocales from '../../../hooks/useLocales';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const { isAuthenticated, user } = useAuth();

  const { translate } = useLocales();

  const navigate = useNavigate();

  const location = useLocation();

  // const [openDepositWithdrawDialog, setOpenDepositWithdrawDialog] = useState({
  //   open: false,
  //   isDeposit: true,
  // });

  const { memberBalance, gameCategsAndCompanies } = useSelector((x) => x.lookup);

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const isDesktop = !useResponsive('down', 'md');

  // const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
  //   useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const categButtons = useMemo(() => {
    const newButtons = [];

    newButtons.push(GAME_CATEGS?.find((x) => x.type === 'POPULAR'));

    if (gameCategsAndCompanies?.length) {
      gameCategsAndCompanies.forEach((gc) => {
        const found = GAME_CATEGS?.find((x) => gc.type === x.type);
        if (found) newButtons.push(found);
      });
    }

    return newButtons;
  }, [gameCategsAndCompanies]);

  const renderContent = (
    <Scrollbar
    // sx={{
    //   height: 1,
    //   '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
    // }}
    >
      <Stack
        spacing={2}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          alignItems: 'center',
        }}
      >
        <Image
          sx={{ width: '80%', cursor: 'pointer' }}
          onClick={() => navigate(PATH_DASHBOARD.root)}
          // src={`${S3_ASSETS_URL}home/zk7-logo-no-bg.webp`}
          src={require('../../../assets/logo-web.png')}
        />

        {/* APP DOWNLOAD SECTION DON'T DELETE! */}
        {/* <Box>
          <Grid container>
            <Grid item xs={12} sx={{ display: { xl: 'none', lg: 'block', md: 'block', sm: 'block', xs: 'block' } }}>
              <Box
                sx={{
                  borderColor: '#C99D43',
                  borderStyle: 'solid',
                  borderWidth: { xs: 0, sm: 0, md: 0, lg: 0, xl: '0px 0px 0px 2px' },
                  margin: '0px -5px 0px 0px',
                  padding: '5px 0px 5px 10px',
                  width: '100%',
                }}
              >
                <Stack sx={{ alignItems: 'center' }}>
                  <Typography
                    className="gold-text"
                    fontWeight={900}
                    sx={{ fontSize: { xl: '15px', lg: '13px', md: '11px', sm: '10px' } }}
                  >
                    ZK99 Game APP
                  </Typography>
                  <Typography sx={{ fontSize: { xl: '12px', lg: '11px', md: '10px', sm: '10px' } }}>
                    Download & Play Today
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={12} xl={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component={m.div}
                animate={{ scale: [0.8, 1, 1, 1, 1, 1, 1, 0.8], rotate: [0, 5, -5, 5, -5, 5, -5, 0] }}
                transition={{
                  type: 'spring',
                  stiffness: 260,
                  damping: 20,
                  repeat: Infinity,
                  repeatDelay: 1,
                  duration: 1,
                }}
                sx={{ width: '100%' }}
              >
                <Image src={`${S3_ASSETS_URL}/app-download1.webp`} />
              </Box>
            </Grid>
            <Grid item xs={8} sx={{ display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' } }}>
              <Box
                sx={{
                  borderColor: '#C99D43',
                  borderStyle: 'solid',
                  borderWidth: { xs: 0, sm: 0, md: 0, lg: 0, xl: '0px 0px 0px 2px' },
                  margin: '0px -5px 0px 0px',
                  padding: '5px 0px 5px 10px',
                  width: '100%',
                }}
              >
                <Stack sx={{ alignItems: { xl: 'center', lg: 'normal', md: 'normal', sm: 'normal', xs: 'normal' } }}>
                  <Typography className="gold-text" fontWeight={900} sx={{ fontSize: '15px' }}>
                    ZK99 Game APP
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>Download & Play Today</Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box> */}

        {!isAuthenticated ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={1} onClick={() => dispatch(openLogin())}>
              <GoldBorderButton
                sx={{ color: 'white', fontWeight: 900, width: '100%', textAlign: 'center' }}
                component={m.div}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.8 }}
              >
                <Typography
                  fontSize={{ xs: '1vw', sm: '1vw', md: '0.9vw', lg: '0.7vw', xl: '0.8vw' }}
                  fontWeight={900}
                >
                  {translate('login')}
                </Typography>
              </GoldBorderButton>
              {/* <Grid item sm={12} md={12} lg={6} onClick={() => dispatch(openRegister())}>
                <GoldBorderButton
                  sx={{ width: '100%' }}
                  component={m.div}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Typography
                    className="gold-text"
                    fontWeight={900}
                    fontSize={{ xs: '1vw', sm: '1vw', md: '0.9vw', lg: '0.7vw', xl: '0.8vw' }}
                  >
                    {translate('register')}
                  </Typography>
                </GoldBorderButton>
              </Grid> */}
            </Grid>
          </Box>
        ) : (
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountPopover />
                <Typography sx={{ ml: 1 }}>
                  {translate('hello')}, {user?.username}
                </Typography>
              </Box>
              <NotificationsPopover />
            </Box>
            <Card
              className="gold-border-button"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 1,
                cursor: 'pointer',
                px: 1.5,
              }}
              onClick={() =>
                dispatch(
                  openTransactionsDialog({
                    open: true,
                    isDeposit: true,
                  })
                )
              }
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Iconify icon={'tdesign:money'} sx={{ color: 'primary.main', mr: 1, fontSize: 20 }} />
                <Typography variant="subtitle2">{memberBalance?.currencyCode || ''}</Typography>
              </Box>
              {memberBalance?.isLoading && !memberBalance?.displayBalance ? (
                <CircularProgress size={'1rem'} />
              ) : (
                <Typography variant="subtitle2">{memberBalance?.displayBalance || 0}</Typography>
              )}
            </Card>
            <Stack direction="row" justifyContent={'center'}>
              <Image
                onClick={() =>
                  dispatch(
                    openTransactionsDialog({
                      open: true,
                      isDeposit: true,
                    })
                  )
                }
                src={`${S3_ASSETS_URL}home/navigation/${appLocale}/deposit-icon.png`}
                sx={{ width: { xl: '70px', lg: '70px' }, cursor: 'pointer' }}
              />
              <Image
                onClick={() =>
                  dispatch(
                    openTransactionsDialog({
                      open: true,
                      isDeposit: false,
                    })
                  )
                }
                src={`${S3_ASSETS_URL}home/navigation/${appLocale}/withdraw-icon.png`}
                sx={{ width: { xl: '70px', lg: '70px' }, cursor: 'pointer' }}
              />
            </Stack>
          </Stack>
        )}

        <Box sx={{ width: '100%' }}>
          <SocialIcons GridContainerProps={{ sx: { justifyContent: 'center' } }} isFullRow={true} />
        </Box>

        <Divider
          variant="middle"
          sx={{
            width: '100%',
            borderWidth: '2px',
            borderImageSource: 'linear-gradient(45deg, #f1d563, #976c2b)',
            borderImageSlice: 1,
            display: { xl: 'block', lg: 'block', md: 'none', sm: 'none' },
          }}
        />

        <Box sx={{ width: '100%', display: { xl: 'block', lg: 'block', md: 'none', sm: 'none' } }}>
          <Typography variant="h6">{translate('game_menu')}</Typography>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Stack spacing={1}>
            {categButtons?.map((x) => (
              <Box
                key={x.type}
                onClick={() => {
                  dispatch(setSelectedGameTab(x.type));
                  navigate('/', {
                    state: {
                      toScroll: 'games',
                      isFromOtherPage: location.pathname.indexOf('home') === -1,
                    },
                  });
                }}
              >
                <Box
                  component={m.div}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.8 }}
                  // sx={{ height: '4.5vw', width: '100%' }}
                  sx={{ width: '100%' }}
                >
                  <Image
                    alt="tab"
                    src={x.btnImageUrl}
                    style={{
                      cursor: 'pointer',
                      // height: '100%',
                      width: '100%',
                    }}
                  />
                </Box>
              </Box>
            ))}
            <Box
              onClick={() =>
                navigate('/', {
                  state: {
                    toScroll: 'bonus',
                    isFromOtherPage: location.pathname.indexOf('home') === -1,
                  },
                })
              }
            >
              <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
                <Image
                  sx={{ cursor: 'pointer' }}
                  src={`${S3_ASSETS_URL}home/game_menu/${appLocale ? `${appLocale}/` : ''}bonusx.webp`}
                />
              </Box>
            </Box>
            <Box onClick={() => navigate('/angpau')}>
              <Box component={m.div} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.8 }}>
                <Image
                  sx={{ cursor: 'pointer' }}
                  src={`${S3_ASSETS_URL}home/angpau/${appLocale}/claim_angpau_button.png`}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Stack>

      {/* <Box sx={{ flexGrow: 1 }} /> */}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        width: NAVBAR.DASHBOARD_WIDTH,
      }}
    >
      {/* {isDesktop && ( */}
      <Drawer
        open={isDesktop}
        variant="persistent"
        // onMouseEnter={onHoverEnter}
        // onMouseLeave={onHoverLeave}
        PaperProps={{
          sx: {
            width: NAVBAR.DASHBOARD_WIDTH,
            borderStyle: 'solid',
            borderWidth: '0px 3px 0px 0px',
            borderColor: '#e2bb54',
            boxShadow: '10px 0px 20px 0px #000000',
            zIndex: 10,
            bgcolor: 'black',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
          },
        }}
      >
        {renderContent}
      </Drawer>
      {/* )} */}
    </Box>
  );
}
