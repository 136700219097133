// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  // login
  login: '登录',

  // register
  register: '注册',

  // home
  game_partners: '游戏合作伙伴',
  responsible_gaming: '责任游戏',
  supported_bank: '支持的银行',
  load_more: '加载更多',

  // game
  choose_your_favourite_x_games: '选择你最喜欢的 {{x}} 游戏',
  choose_your_favourite_x: '选择你最喜欢的 {{x}}',
  launch: '启动',

  // notification
  notifications: '通知',
  you_have_x_unread_messages: '你有 {{x}} 条未读消息',

  // deposit/withdraw
  drop_or_select_file: '拖放或选择文件',
  drop_files_here: '拖放文件到这里',
  bank: '银行',
  bank_name: '银行名称',
  account_name: '账户名称',
  account_number: '账户号码',
  bank_account: '银行账户',
  something_went_wrong: '发生了错误。请联系支持。',
  deposit_amount_between_x_and_y: '存款金额必须在 {{x}} 到 {{y}} 之间',
  minimum_amount_is_x_y: '最低金额为 {{x}}{{y}}',
  payment_method: '支付方式',
  mode: '模式',
  transfer_to: '转移到',
  select: '选择',
  account: '账户',
  amount: '金额',
  deposit: '存款',
  withdraw: '取款',
  amount_no_exceed_balance: '取款金额不得超过可用余额',
  minimum_withdraw_amount_is_x: '最低取款金额为 {{x}}',
  maximum_withdraw_amount_is_x: '最高取款金额为 {{x}}',
  available_balance: '可用余额',
  amount_withdrawn: '取款金额',
  no_bank_account: '您没有可提款的账户，请立即添加',
  add_account: '添加账户',
  account_name_must_be_4: '账户名称长度必须大于4',
  please_select_bank: '请选择一个银行账户',
  account_number_must_be_x: '账户号码必须为6-10位',
  minimum_length_is_x: '最小长度为 {{x}}',
  maximum_length_is_x: '最大长度为 {{x}}',
  withdraw_information: '取款信息',
  account_holder_name: '账户持有人姓名',
  name_of_the_account_holder: '账户持有人姓名',
  fill_in_bank_account: '填写银行账户',
  please_upload_receipt: '请上传收据',
  deposit_bonus: '存款奖金',
  bank_transfer: '银行转账',
  upload_receipt: '上传收据',
  select_bonus: '选择奖金',
  no_promotion: '无促销活动',

  // transaction history
  transaction_history: '交易记录',

  // bet history
  bet_history: '投注记录',
  game_provider: '游戏提供商',
  bet_count: '投注次数',
  bet_amount: '投注金额',
  win_loss: '赢/输',
  rebate: '返利',
  rebates: '返利',
  turnover: '周转',
  game_category: '游戏类别',

  // all
  all: '全部',
  x_is_required: '{{x}} 是必需的',
  note: '备注',
  save: '保存',
  please_select_x: '请选择 {{x}}',
  title: '标题',
  date: '日期',
  type: '类型',
  status: '状态',
  remark: '备注',
  clear: '清除',
  date_from: '起始日期',
  date_to: '结束日期',
  category: '类别',
  search: '搜索',
  new: '新建',
  change_password: '更改密码',
  logout: '退出登录',
  username: '用户名',
  password: '密码',
  forgot_password: '忘记密码',
  confirm: '确认',
  dont_have_account: '没有用户账户？',
  contact_us: '联系我们',
  game_menu: '游戏菜单',
  bonus: '奖金',
  no_data: '无数据',
  terms_and_condition: '条款和条件',
  game_list: '游戏列表',
  play: '播放',
  games: '游戏',
  casino: '赌场',
  slots: '老虎机',
  card: '纸牌',
  fishing: '捕鱼',
  sport: '运动',
  sports: '体育',
  bonuses: '奖金',
  account_profile: '账户资料',
  email: '电子邮件',
  full_name: '全名',
  phone_number: '电话号码',
  level: '等级',
  bank_list: '银行列表',
  in_maintenance: '维护中',
  birthday: '生日',
  birthday_bonus: '输入你的生日以获得奖金！🎉🎁',
  referral_code: '推荐码',
  invalid_x: '无效的{{x}}',
  extra: '额外',
  referral: '推荐',
  my_referral: '我的推荐',
  my_claim: '我的申请',
  claim_profit: '领取利润',
  available_profit: '可用利润',
  claimed_profit: '已领取的利润',
  all_time_profit: '所有时间利润',
  referral_note_on_x: '每次您的朋友在{{x}}上玩时赚取利润',
  tier: '等级',
  commission: '佣金',
  referred_member: '推荐成员',
  profit_earned: '赚取的利润',
  account_information: '账户信息',
  invite_friends: '邀请朋友',
  how_it_work: '如何运作？',
  invite_note: '当您的朋友使用您的推荐码注册时，您将获得独家推荐奖金！',
  share_via: '分享方式',
  referral_link: '推荐链接',
  total_profit: '总利润',
  copied: '已复制',
  nothing_to_claim: '没有可领取的',
  claim_history: '领取记录',
  from: '从',
  to: '到',
  choose_date: '选择日期',
  select_both_date: '选择开始和结束日期',
  hello: '你好',
  my_downline: '我的下线',
  downline: '下线',
  forgot_password_contact: '请联系下面提供的客户支持来帮助您更改密码',
  submit: '提交',
  current_password: '当前密码',
  new_password: '新密码',
  confirm_new_password: '确认新密码',
  already_have_an_account: '已经有一个账户？',
  share: '分享',
  more_info: '更多信息',
  copy_link: '复制链接',
  share_to_friend: '分享给朋友',
  get_bonus: '获取奖金',
  copy_your_referral: '复制您的推荐链接并发送到社交媒体，从您的下线中获得积分！',
  last_x_digit_bank_ref_number: '银行参考号的最后{{x}}位',
  bank_ref_number_validation_x: '银行参考号是必填的，必须为{{x}}个字符长度',
  card_game: '卡牌游戏',
  e_sports: '电子竞技',
  instant_game: '即时游戏',
  sort: '排序',
  name: '名字',
  default: '默认',
  e_sports_bonus: '电子竞技奖金',
  fishing_bonus: '捕鱼奖金',
  casino_bonus: '赌场奖金',
  sports_bonus: '运动奖金',
  slots_bonus: '老虎机奖金',
  instant_game_bonus: '即时游戏奖金',
  card_game_bonus: '卡牌游戏奖金',
  all_bonus: '所有奖金',
  how_to_get_referral_bonus: '如何获得推荐朋友奖金？',
  steps_to_claim: '领取奖金的步骤',
  share_your_referral_code:
    '1. 将您的推荐代码分享给朋友，通过Viber、Messenger、Telegram、Facebook、Twitter或Instagram注册账户。',
  after_friend_register: '2. 朋友注册ZK99后，推荐人和被推荐人都需要存款才能领取此奖金。',
  refer_a_friend_bonus: '3. 推荐朋友奖金计算：',
  turnover_played: '投注额 x 佣金百分比 = 推荐朋友奖金',
  example: '例子：',
  turnover_formula_x: '投注额（1,000,000）x 0.002%（佣金百分比）= 2,000{{x}}',
  note_bonus_only: '*注：奖金只能在次日凌晨12点后领取',
  note_bonus_only_x: '*注：奖金只能在次日{{x}}后领取',
  access_dashboard: '4. 访问仪表盘检查应得利润、佣金等级、申领奖励记录和下线情况。',
  click_this_button_to_claim: '点击此按钮领取推荐朋友奖金',
  commision_tier: '佣金等级',
  member_downline: '会员下线',
  bonus_tutorial_video: '奖金教程视频',
  popularity: '人气',
  no_balance: '您的余额不足，无法进行操作',
  invalid_captcha: '无效的验证码',
  captcha: '验证码',
  claim_bonus: '领取奖金',
  claimed: '已领取',
  bonus_claimed: '奖金已领取',
  online_banking: '网上银行',
  please_enter_email: '请输入您的电子邮件地址以继续密码重置过程',
  please_select_a_bank: '请选择一个银行',
  deposit_from_bank: '从银行存款',
  from_selected_promotion: '从选定的促销活动',
  from_selected_bank: '从选定的银行',
  network_error: '网络错误',
  we_have_sent_otp: '我们已向您的电话号码发送了一次性密码',
  your_otp_expire: '您的一次性密码将在',
  didnt_receive: '没有收到信息？',
  send_again: '再次发送',
  resend_again: '再次发送时间',
  invalid_verification: '无效的验证码！',
  otp_expired: '一次性密码已过期，请重新输入您的电话号码并重试！',
  otp_expired_redirect: '一次性密码已过期！重定向到登录页面',
  captcha_check_failed: '验证码检查失败！',
  verify_phonenumber: '验证电话号码',
  send_otp: '发送一次性密码',
  success_verification: '您的电话号码已验证成功！请填写下面的表格以完成注册。',
  dont_show_again: '不再显示',
  next: '下一步',
  back: '返回',
  close: '关闭',
  payment_draft: '如果您确认已经付款，请稍等片刻，因为支付可能仍在处理。',
  go_to_home: '回到主页',
  payout: '支付',
  get: '获得',
  deposit_fee: '存款费用',
  total_amount_received: '总收到金额',
  cancel: '取消',
  deposit_amount: '存款金额',
  confirm_to_pay: '确认付款',
  deposit_method: '存款方式',
  redeem_angpau: '兑换红包',
  promo_concluded: '促销活动结束',
  can_only_claim: '只能在新加坡时间{{x}}至{{y}}之间领取奖金',
  please_login: '请登录',
  terms_n_conditions: '条款和条件',
  claim_now: '立即领取',
  the_activity_x_in: '活动{{x}}中',
  starts: '开始',
  ends: '结束',
  list_of_winners: '获奖者名单',
  days: '天',
  hours: '小时',
  minutes: '分钟',
  seconds: '秒',
};

export default cn;
