import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Image from '../../../components/Image';
import { InteractiveMarquee } from './InteractiveMarquee';
import useResponsive from '../../../hooks/useResponsive';
import { useSelector } from '../../../redux/store';
import { S3_ASSETS_URL } from '../../../config';

const Announcement = (props) => {
  const { siteSettings } = useSelector((x) => x.lookup);

  const isSm = useResponsive('down', 'md');

  return (
    <Box
      sx={{
        width: isSm ? '70%' : '95%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => {
        if (siteSettings?.announcement_text?.WalkPromotion?.url) {
          window.open(siteSettings.announcement_text.WalkPromotion.url, '_blank');
        }
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          width: `calc(94% - ${isSm ? '15%' : '5%'})`,
          display: 'flex',
          overflowX: 'hidden',
          position: 'absolute',
          ml: isSm ? '9%' : '4%',
        }}
      >
        {/* <Image sx={{ width: isSm ? '14%' : '4%' }} src={`${S3_ASSETS_URL}home/Ditty-Icon.webp`} /> */}
        {siteSettings?.announcement_text?.WalkPromotion?.content ? (
          <InteractiveMarquee
            content={siteSettings?.announcement_text?.WalkPromotion?.content}
            sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '0.9rem', lg: '0.9rem', xl: '0.9rem' } }}
          />
        ) : (
          ''
        )}
        {/* <InteractiveMarquee
          content="Play at the best online casino - ZK99 in Myanmar! More than 1000 popular casino games, slot games, sports
          games, card games, and fishing games for you to play with. Find your favorite games such as SA gaming, Sexy
          Baccarat, AllBet, AK Gaming, PG Soft Gaming, Pragmatic Play, Joker, Ace333, and many more to play in ZK99. We
          are the only online casino in Myanmar that could instantly deposit and withdraw all your deposits and win
          within a few seconds. So trust ZK99 and start registering to play today"
        /> */}
      </Box>
      <Image
        sx={{ zIndex: -1 }}
        src={isSm ? `${S3_ASSETS_URL}home/Ditty-Bg-Mobile.png` : `${S3_ASSETS_URL}home/Ditty-Bg1.webp`}
      />
      <Image
        sx={{
          position: 'absolute',
          left: isSm ? '6%' : '3%',
          top: isSm ? '25%' : '15%',
          width: isSm ? '8%' : '4%',
          zIndex: 2,
        }}
        src={`${S3_ASSETS_URL}home/Ditty-Icon.webp`}
      />
    </Box>
  );
};

Announcement.propTypes = {};

export default Announcement;
